export const SCREEN_L = 1024;
export const SCREEN_M = 768;
export const SCREEN_S = 500;

export const INITIAL_NUMBER_OF_FILMS_L = 16;
export const INITIAL_NUMBER_OF_FILMS_M = 12;
export const INITIAL_NUMBER_OF_FILMS_S = 8;
export const INITIAL_NUMBER_OF_FILMS_XS = 5;

export const POSTION_OF_FILMES_L = 4;
export const POSTION_OF_FILMES_M = 3;
export const POSTION_OF_FILMES_S = 2;