import './Promo.css';

function Promo() {
	return (
		<section className="promo">
			<h1 className="promo__title">Учебный проект студента факультета Веб&#8209;разработки.</h1>
		</section>
	);
}

export default Promo;